define("discourse/plugins/discourse-virtmail/discourse/adapters/address", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class VirtmailAdressesAdapter extends _rest.default {
    jsonMode = true;
    basePath() {
      return "/discourse-virtmail/";
    }
  }
  _exports.default = VirtmailAdressesAdapter;
});