define("discourse/plugins/discourse-virtmail/discourse/templates/discourse-virtmail-oauth2-authorize", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "discourse-virtmail.oauth2.authorize.title"}}</h1>
  
  {{#if hasAddresses}}
  Bitte wähle eine E-Mail-Adresse:
  <ul>
  {{#each addresses as |addr|}}
  <li><a {{action "authorize" addr.address}}>{{addr.address}}</a>{{#if addr.comment}} ({{addr.comment}}){{/if}}</li>
  {{/each}}
  </ul>
  {{else}}
  Für dich sind keine E-Mail-Adressen konfiguriert.
  {{/if}}
  
  */
  {
    "id": "rdgd576C",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[\"discourse-virtmail.oauth2.authorize.title\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasAddresses\"]],[[[1,\"Bitte wähle eine E-Mail-Adresse:\\n\"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"addresses\"]]],null]],null],null,[[[10,\"li\"],[12],[11,3],[4,[38,8],[[30,0],\"authorize\",[30,1,[\"address\"]]],null],[12],[1,[30,1,[\"address\"]]],[13],[41,[30,1,[\"comment\"]],[[[1,\" (\"],[1,[30,1,[\"comment\"]]],[1,\")\"]],[]],null],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[]],[[[1,\"Für dich sind keine E-Mail-Adressen konfiguriert.\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `hasAddresses` property path was used in the `discourse/plugins/discourse-virtmail/discourse/templates/discourse-virtmail-oauth2-authorize.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.hasAddresses}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `addresses` property path was used in the `discourse/plugins/discourse-virtmail/discourse/templates/discourse-virtmail-oauth2-authorize.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.addresses}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"addr\"],false,[\"h1\",\"i18n\",\"if\",\"ul\",\"each\",\"-track-array\",\"li\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-virtmail/discourse/templates/discourse-virtmail-oauth2-authorize.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});