define("discourse/plugins/discourse-virtmail/discourse/controllers/oauth2-authorize", ["exports", "@ember/object", "@ember/controller", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    addresses: [],
    init() {
      this._super(arguments);
      (0, _ajax.ajax)(`/discourse-virtmail/oauth2/authorize`, {
        type: "GET"
      }).then(result => {
        this.set("addresses", result.addresses);
      }).catch(_ajaxError.popupAjaxError);
    },
    get hasAddresses() {
      // console.log("hasAddresses")
      return true;
      return !!this.addresses.length;
    },
    authorize(email) {
      return (0, _ajax.ajax)(`/discourse-virtmail/oauth2/authorize`, {
        type: "POST",
        data: {
          email: email,
          search: location.search.substr(1)
        }
      }).then(result => {
        location.href = result.location;
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "hasAddresses", [(0, _object.computed)("addresses")]], ["method", "authorize", [_object.action]]]));
});