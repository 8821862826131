define("discourse/plugins/discourse-virtmail/discourse/controllers/addresses-show", ["exports", "@ember/object", "@ember/controller", "I18n", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse-common/lib/get-url", "@ember/utils", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _I18n, _computed, _decorators, _utilities, _getUrl, _utils, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Controller from "@ember/controller";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    // addresses: controller(),

    addresses: {},
    savingStatus(isSaving, saved, saveButtonDisabled) {
      if (isSaving) {
        return _I18n.default.t("saving");
      } else if (!saveButtonDisabled && saved) {
        return _I18n.default.t("saved");
      }
      // Use side effect of validation to clear saved text
      this.set("saved", false);
      return "";
    },
    saveButtonText(isNew) {
      return isNew ? _I18n.default.t("admin.web_hooks.create") : _I18n.default.t("admin.web_hooks.save");
    },
    save() {
      this.set("saved", false);
      const model = this.model;
      const isNew = model.get("isNew");
      return model.save().then(() => {
        this.set("saved", true);
        if (isNew) {
          this.transitionToRoute("discourse-virtmail.addresses.show", model.get("id"));
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "savingStatus", [(0, _decorators.default)("model.isSaving", "saved", "saveButtonDisabled")]], ["method", "saveButtonText", [(0, _decorators.default)("model.isNew")]], ["method", "save", [_object.action]]]));
});