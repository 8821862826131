define("discourse/plugins/discourse-virtmail/discourse/routes/discourse-virtmail-addresses", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Addressess extends _discourse.default {
    controllerName = "addresses";
    show(address) {
      this.transitionTo("discourse-virtmail.addresses.show", address.id);
    }
    static #_ = (() => dt7948.n(this.prototype, "show", [_object.action]))();
    new() {
      this.transitionTo("discourse-virtmail.addresses.new");
    }
    static #_2 = (() => dt7948.n(this.prototype, "new", [_object.action]))();
  }
  _exports.default = Addressess;
});