define("discourse/plugins/discourse-virtmail/discourse/routes/discourse-virtmail-addresses-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddressesShow extends _discourse.default {
    controllerName = "addresses-show";
    model(params) {
      if (params.id === "new") {
        return this.store.createRecord("address");
      }
      return this.store.find("address", params.id);
    }
  }
  _exports.default = AddressesShow;
});