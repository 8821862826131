define("discourse/plugins/discourse-virtmail/discourse/connectors/user-preferences-security/virtmail-password", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    resetVirtmailPassword(model) {
      (0, _ajax.ajax)(`/discourse-virtmail/u/${this.get("model.username_lower")}/reset_password`, {
        type: "POST"
      }).then(json => {
        this.model.set("virtmail_password", json.password);
      }).catch(_ajaxError.popupAjaxError);
    }
  }, [["method", "resetVirtmailPassword", [_object.action]]]);
});