define("discourse/plugins/discourse-virtmail/discourse/routes/discourse-virtmail-oauth2-authorize", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Oauth2Authorize extends _discourse.default {
    controllerName = "oauth2-authorize";
  }
  _exports.default = Oauth2Authorize;
});